<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>部门绩效</span>
        <div>
          <el-radio-group v-model="dateSelect" size="small" @change="dateChange">
            <el-radio-button label="month">月</el-radio-button>
            <el-radio-button label="quarter">季</el-radio-button>
            <el-radio-button label="year">年</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div style="display:flex;justify-content:space-between;">
        <div class="search" v-if="roleId != 4">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="人员">
              <el-select size="small" v-model="formInline.staffId" clearable placeholder="请选择人员">
                <el-option
                  v-for="item in yuangong"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="季度" v-show="selectDate=='quarter'">
              <el-select size="small" v-model="formInline.quarter" clearable placeholder="请选择季度">
                <el-option
                  v-for="item in quarters"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-date-picker size="small" v-model="formInline.month" type="month" placeholder="请选择月"></el-date-picker>
          <el-button type="primary" @click="report" size="small">导出报表</el-button>
        </div>
      </div>
      
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="staffName" label="人员名称">
            <template slot-scope="scope">
              <span>{{ scope.row.staffName?scope.row.staffName:'-' }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="departmentName" label="所属部门"></el-table-column> -->
          <el-table-column prop="completeCount" label="完成数量"></el-table-column>
          <el-table-column prop="missCount" label="漏检数量"></el-table-column>
          <el-table-column prop="submitCount" label="提交数量">
            <template slot-scope="scope">
              <span>{{ scope.row.submitCount?scope.row.submitCount:0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="totalCount" label="任务总数"></el-table-column>
          <el-table-column label="完成率">
            <template slot-scope="scope">
              {{ (Number(scope.row.completeCount/scope.row.totalCount) * 100).toFixed(1) +'%' }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="完成时间">
            <template slot-scope="scope">
                {{scope.row.createTime ? dayjs(scope.row.createTime).format("YYYY-MM") : "-"}}
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                  <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                  <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column> -->
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :modal="false"
      class="export-dialog">
      <div>
        正在导出中，稍后请在
        <span>【<el-button type="text" @click="toMessage">消息中心</el-button>】</span>
        <span><i class="el-icon-arrow-right"></i></span>
        <span>【<el-button type="text" @click="toMessage">待下载列表</el-button>】</span>
        模块中查看
      </div>
    </el-dialog>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from "dayjs";
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {
        month: ''
      },
      tableData: [],
      dateSelect: 'month',
      isChangeOpen: 0,
      isDetailOpen: 0,
      isEditOpen: 0,
      yuangong: [],
      quarters: [
        {id: 1,name: '第一季度'},
        {id: 2,name: '第二季度'},
        {id: 3,name: '第三季度'},
        {id: 4,name: '第四季度'}
      ],
      selectDate: 'month',
      detailRow: {},
      editRow: {},
      roleId: 0
    };
  },
  methods: {
    dayjs,
    edit(row) {
      this.$router.push({ name: 'deviceListEdit', params: {
        row: row,
        bumen: this.bumen,
        leixin: this.leixin,
        yuangong: this.yuangong
      }})
    },
    details(row) {
      this.$router.push({ name: 'deviceListDetail', params: {row: row}})
    },
    dlt(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('deviceDelete', {
          id: row.id
        }).then(res => {
          this.loadListData()
        })
      })
    },
    toMessage() {
      this.$router.push({ name: 'stayDownloadList' })
    },
    // 巡检分析报表PDF导出
    report() {
      if(!this.formInline.month) {
        return this.$message.error('请选择月份进行导出')
      }
      let selectTime = new Date(this.formInline.month)
      let year = selectTime.getFullYear()
      let month = selectTime.getMonth()+2
      if(month>12) {
        year = selectTime.getFullYear()+1
        month = 1
      }
      let y = year<10?'0'+year:year
      let m = month<10?'0'+month:month
      let time = y+'-'+m+'-'+'01'+' '+'00'+':'+'00'+':'+'00'
      let monthTime = new Date(time).getTime()
      // console.log(time)
      // console.log(monthTime)
      let form = {
        monthTime: monthTime,
      }
      this.loading = true;
      this.$ajax.post("cycleAnalysisReport", form).then((res) => {
        if(res.code==0) {
          this.loading = false;
          this.dialogVisible = true
        }else {
          this.loading = false;
          this.dialogVisible = false
          this.$message.error('导出失败')
        }
      }).catch(err=>{
        this.loading = false
        this.dialogVisible = false
      })
    },
    // 列表筛选查询
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      if(this.selectDate == 'month') {
        this.loadListData()
      }
      if(this.selectDate =='quarter') {
        
      }
      if(this.selectDate =='year') {
        
      }
    },
    // 月季年筛选事件
    dateChange(e) {
      this.selectDate = e
      if(e=='month') {
        this.loadListData()
      }
      if(e=='quarter') {
        this.tableData = []
        this.total = 0
      }
      if(e=='year') {
        this.tableData = []
        this.total = 0
      }
    },
    // 获取列表数据
    loadListData() {
      this.loading = true
      this.$ajax.post('cycleAchievement', {
        selectedStaffId: this.formInline.staffId?this.formInline.staffId:null,
        pageNum: this.page,
        pageSize: this.size,
      }).then(res => {
        this.tableData = res.data.operationMonthAchievementList
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    // 获取所有人员列表
    load_yuangong() {
      this.$ajax.get("staffDueryAll").then((res) => {
        this.yuangong = res.data;
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.load_yuangong();
    this.loadListData()
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
/deep/.export-dialog .el-dialog {
  margin-top: 10vh !important;
  background: #f0f9eb;
  border-radius: 5px;
  box-shadow: 0px 0px 0px;
}
/deep/.export-dialog .el-dialog__header {
  padding: 20px 20px 0px;
}
/deep/.export-dialog .el-dialog__title {
  font-size: 16px;
  color: #67c23a;
}
/deep/.export-dialog .el-dialog__body {
  padding: 10px 20px;
  color: #67c23a;
}
.man-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search {
  padding: 0 10px;
}
</style>